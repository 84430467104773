import { useEffect, useState, useContext } from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { DateTime } from 'luxon';
import DatePicker from 'react-datepicker';

import CustomTooltip from "../../components/CustomTooltip";
import Sidebar from "../../components/Navigation/Sidebar";
import Topbar from "../../components/Navigation/Topbar";
import Header from "../../components/Page/header";
import Grid from '../../components/Grid';

import { Show } from "../../hooks/Show";
import { PermissionContext } from '../../hooks/PermissionContext';

import { getStatusBadgeByCode } from '../../utils/StatusUtils';

import { InventoryReportTypesEnum } from "../../utils/enumsUtil";

const IncidentsManagement = ({
    reduxGetAddresses,
    addresses,
    reduxGetShippers,
    shippers,
    reduxGetOperators,
    logisticsOperators,
    reduxGetNovelties,
    novelties,
}) => {
    const {
        handleSubmit: searchSubmit,
        control: searchControl,
        formState: { errors: searchErrors }
    } = useForm({
        defaultValues: {
            date: null,
            shipper: null,
            operator: null,
            novelty: null,
        }
    });

    const { hasPermission } = useContext(PermissionContext);

    const userData = JSON.parse(window.localStorage.getItem('userData'));
    const countryUser = userData?.idCountry;

    const [currentPage, setCurrentPage] = useState(1);
    const [offset, setOffset] = useState(10);
    const [search, setSearch] = useState('');

    const customStyles = {
        menu: (base) => ({ ...base, zIndex: 9999 }),
        valueContainer: (baseStyles) => ({ ...baseStyles, maxHeight: "4.5vw", overflowY: "auto" }),
        control: (baseStyles) => ({ ...baseStyles, flexWrap: "nowrap" })
    };

    useEffect(() => {
        reduxGetOperators();
        reduxGetShippers();
        reduxGetNovelties();
    }, []);

    const performSearch = (data) => {
        reduxGetAddresses({
            page: 1,
            offset: offset,
            search: search,
        })
    }

    const columns = [
        {
            title: '#',
            render: (rowData) => {
                return <span>{rowData.idAddress}</span>;
            },
            field: 'idAddress',
            searchable: true,
            visible: true,
        },
        {
            title: 'Guía',
            render: (rowData) => {
                return <span>{rowData.trackingId}</span>;
            },
            field: 'trackingId',
            searchable: true,
            visible: hasPermission('feature:rescheduling-show-column-trackingid'),
        },
        {
            title: 'IdExterno',
            render: (rowData) => {
                return <span>{rowData.externalId}</span>;
            },
            field: 'externalId',
            searchable: true,
            visible: hasPermission('feature:rescheduling-show-column-externalid')
        },
        {
            title: 'Nombre Destinatario',
            render: (rowData) => {
                return <span>{rowData.receiverName}</span>;
            },
            visible: hasPermission('feature:rescheduling-show-column-receivername')
        },
        {
            title: 'Dirección Destinatario',
            render: (rowData) => {
                return <span>{rowData.receiverAddress}</span>;
            },
            field: 'address',
            searchable: true,
            visible: hasPermission('feature:show-recipient-address')
        },
        {
            title: 'Teléfono Destinatario',
            render: (rowData) => {
                return (
                    <span>{rowData.receiverPhone}</span>
                );
            },
            field: 'reference1',
            searchable: true,
            visible: hasPermission('feature:show-reference1-address')
        },
        {
            title: 'Estado',
            render: (rowData) => getStatusBadgeByCode(rowData.state),
            visible: hasPermission('feature:rescheduling-show-column-state')
        },
        {
            title: 'Ciudad',
            render: (rowData) => {
                return <span>{rowData.city}</span>;
            },
            field: 'city',
            searchable: true,
            visible: hasPermission('feature:rescheduling-show-column-city')
        },
        {
            title: 'Novedad',
            render: (rowData) => {
                return <span>{rowData.novelty}</span>;
            },
            field: 'novelty',
            searchable: true,
            visible: hasPermission('feature:rescheduling-show-column-novelty')
        },
        {
            title: 'Comentario Novedad/Nota',
            render: (rowData) => {
                return <span>{rowData.comment}</span>;
            },
            visible: hasPermission('feature:rescheduling-show-column-noveltynote')
        },
        {
            title: 'Valor de recaudo',
            render: (rowData) => {
                return <span>{rowData.collection}</span>;
            },
            field: 'collection',
            searchable: true,
            visible: hasPermission('feature:rescheduling-show-column-collection')
        },
        {
            title: 'Producto',
            render: (rowData) => {
                return rowData.product?.length > 30
                    ? <CustomTooltip data={rowData.product.split(',')} />
                    : <span>{rowData.product}</span>;
            },
            visible: hasPermission('feature:rescheduling-show-column-product')
        },
        {
            title: 'Intentos',
            render: (rowData) => (
                <span className='badge badge-pill badge-light d-flex justify-content-center align-items-center'>
                    {rowData.attempts}
                </span>
            ),
            visible: hasPermission('feature:rescheduling-show-column-attempts')
        },
        {
            title: 'Fecha Estado',
            render: (rowData) => {
                return (
                    <span>
                        {rowData.lastUpdateDate && String(rowData.lastUpdateDate).includes('/')
                            ? DateTime.fromFormat(String(rowData.lastUpdateDate), "YYYY/MM/dd").toFormat('dd-MM-YYYY')
                            : rowData.lastUpdateDate
                                ? String(rowData.lastUpdateDate)
                                : ''}
                    </span>
                );
            },
            visible: hasPermission('feature:rescheduling-show-column-lastupdatedate')
        },
        {
            title: 'Fecha Creado',
            render: (rowData) => {
                return (
                    <span>
                        {rowData.createdDate && String(rowData.createdDate).includes('/')
                            ? DateTime.fromFormat(String(rowData.createdDate), "YYYY/MM/dd").toFormat('dd-MM-YYYY')
                            : rowData.createdDate
                                ? String(rowData.createdDate)
                                : ''}
                    </span>
                );
            },
            visible: hasPermission('feature:rescheduling-show-column-createddate')
        },
        {
            title: 'Fecha Tentativa de Entrega',
            render: (rowData) => {
                return (
                    <span>
                        {rowData.deliveryDate && String(rowData.deliveryDate).includes('/')
                            ? DateTime.fromFormat(String(rowData.deliveryDate), "YYYY/MM/dd").toFormat('dd-MM-YYYY')
                            : rowData.deliveryDate
                                ? String(rowData.deliveryDate)
                                : ''}
                    </span>
                );
            },
            visible: hasPermission('feature:rescheduling-show-column-rescheduleddate')
        },
        {
            title: 'Remitente',
            render: (rowData) => {
                return <span>{rowData.shipper}</span>;
            },
            visible: hasPermission('feature:show-client-address'),
        },
        {
            title: 'Operador',
            render: (rowData) => {
                return <span>{rowData.operator}</span>;
            },
            visible: hasPermission('feature:show-operator-address')
        },
        // {
        //   title: 'Acciones',
        //   render: (rowData) => {
        //     return (
        //       <>
        //         <Show when='feature:rescheduling'>
        //           <button
        //             title='Reprogramar'
        //             className='btn btn-warning btn-sm btn-circle mr-2'
        //             type='button'
        //             onClick={(e) => openReschedule(rowData)}>
        //             <i className='fas fa-calendar	fa-xs'></i>
        //           </button>
        //         </Show>
        //         <TrafficLight days={getDaysDiff(new Date(), rowData.lastUpdateDate)} />
        //       </>
        //     );
        //   },
        // },
    ];

    return (
        <div id="wrapper">
            <Sidebar />
            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                    <Topbar />
                    <div className="container-fluid">
                        <Header title={"Gestión Incidencias"} subtitle={"Módulo de gestión de Incidencias"} actionButtons={null} />
                    </div>
                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-primary">
                                Listado de Órdenes
                            </h6>
                        </div>
                        <div className="card-body">
                            <form onSubmit={searchSubmit(performSearch)}>
                                <div className='row mb-1'>
                                    <div className='form-group col-md-3'>
                                        <label
                                            className='form-label'>
                                            Desde - Hasta
                                        </label>
                                        <Controller
                                            control={searchControl}
                                            name="date"
                                            rules={{
                                                required: "El rango de fecha es requerido"
                                            }}
                                            render={({ field }) => (
                                                <>
                                                    <DatePicker
                                                        {...field}
                                                        // startDate={startDate}
                                                        // endDate={endDate}
                                                        selectsRange
                                                        // ref={rangeRef}
                                                        selectsDisabledDaysInRange
                                                        // onChange={changeEndDate}
                                                        dateFormat="yyyy-MM-dd"
                                                        className={'form-control'}
                                                        maxDate={new Date()}
                                                        shouldCloseOnSelect={false}
                                                        isClearable
                                                    />
                                                    {
                                                        searchErrors.date &&
                                                        <span className="error-message">{searchErrors.date.message}</span>
                                                    }
                                                </>
                                            )}
                                        />
                                    </div>
                                    <div className='form-group col-md-3'>
                                        <label
                                            htmlFor='shipper'
                                            className='form-label'>
                                            Remitente
                                        </label>
                                        <Controller
                                            control={searchControl}
                                            name="shipper"
                                            render={({ field }) => (
                                                <>
                                                    <Select
                                                        {...field}
                                                        styles={customStyles}
                                                        isClearable={true}
                                                        options={
                                                            shippers && Object.keys(shippers).length > 0
                                                                ? shippers.items
                                                                    .filter((e) => e.isActive && e.country == countryUser && countryUser)
                                                                    .map((ele, key) => ({
                                                                        value: ele.idCompany,
                                                                        label: ele.description,
                                                                    }))
                                                                : []
                                                        }
                                                    />
                                                </>
                                            )}
                                        />
                                    </div>
                                    <div className='form-group col-md-3'>
                                        <label
                                            htmlFor='operator'
                                            className='form-label'>
                                            Operador
                                        </label>
                                        <Controller
                                            control={searchControl}
                                            name="operator"
                                            render={({ field }) => (
                                                <>
                                                    <Select
                                                        {...field}
                                                        styles={customStyles}
                                                        isClearable={true}
                                                        options={
                                                            logisticsOperators &&
                                                            Object.keys(logisticsOperators).length > 0 &&
                                                            logisticsOperators.items
                                                                .filter((e) => e.isActive === true && e.country == countryUser && countryUser)
                                                                .map((ele, key) => ({
                                                                    value: ele.idCompany,
                                                                    label: ele.description,
                                                                }))
                                                        }
                                                    />
                                                </>
                                            )}
                                        />
                                    </div>
                                    <div className='form-group col-md-3'>
                                        <label
                                            htmlFor='novelty'
                                            className='form-label'>
                                            Novedad
                                        </label>
                                        <Controller
                                            control={searchControl}
                                            name="novelty"
                                            render={({ field }) => (
                                                <>
                                                    <Select
                                                        {...field}
                                                        styles={customStyles}
                                                        isClearable={true}
                                                        options={
                                                            novelties &&
                                                            Object.keys(novelties).length > 0 &&
                                                            novelties
                                                                .filter((f) => f.isActive === true)
                                                                .map((ele, key) => ({
                                                                    value: ele.code,
                                                                    label: ele.description,
                                                                }))
                                                        }
                                                    />
                                                </>
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className='form-group'>
                                    <Button
                                        className='mr-2'
                                        variant='primary'
                                        type='submit'
                                        id='btnSearch'>
                                        Buscar
                                    </Button>
                                </div>
                            </form>
                            <Grid
                                className='stickyAddress'
                                cols={columns}
                                data={addresses && Object.keys(addresses).length > 0 ? addresses.items : []}
                                total={addresses && addresses.hasOwnProperty('total') ? addresses.total : 0}
                                page={addresses && Object.keys(addresses).length > 0 ? Number(addresses.page) : currentPage}
                                pages={addresses && Object.keys(addresses).length > 0 ? Number(addresses.totalPages) : 0}
                                offset={offset}
                                defaultValue={search}
                                onChangePage={(value) => {
                                    setCurrentPage(value)
                                    reduxGetAddresses({
                                        page: value,
                                        offset: offset,
                                        search: search,
                                    });
                                }
                                }
                                onChangeRange={(value) => {
                                    setOffset(value);
                                    reduxGetAddresses({
                                        page: 1,
                                        offset: value,
                                        search: search,
                                    });
                                }}
                                onChangeSearch={(value) => {
                                    setSearch(value);
                                    reduxGetAddresses({
                                        page: 1,
                                        offset: offset,
                                        search: value,
                                    });
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        addresses: state.addressState.reschedulings,
        shippers: state.companyState.clients,
        logisticsOperators: state.companyState.logisticsOperators,
        novelties: state.noveltyState.novelties,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        reduxGetAddresses: (payload) =>
            dispatch({
                type: 'FETCH_ADDRESSES_RESCHEDULING_REQUEST',
                value: payload,
            }),
        reduxGetShippers: (payload) =>
            dispatch({
                type: 'FETCH_COMPANIESBYSHIPPER_REQUEST',
                value: payload,
            }),
        reduxGetOperators: (payload) =>
            dispatch({
                type: 'FETCH_COMPANIESBYLOGISTICSOPERATOR_REQUEST',
                value: payload,
            }),
        reduxGetNovelties: (payload) =>
            dispatch({
                type: 'FETCH_NOVELTIES_FOR_INCIDENTS_REQUEST',
                value: payload,
            }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(IncidentsManagement);